if(window.angular){

            var PRSearchAngularApp = angular.module('SearchMunicipiosPR',[]);

            PRSearchAngularApp.controller('SearchCtrl', function($scope, $window) {

                $scope.error = false;

                $scope.onSearch = function(){
                    if($scope.searchTerm && $scope.searchTerm.length>3){
                        $scope.error = false;
                        $window.location.href = '/buscar?q='+$scope.searchTerm;
                    } else {
                        $scope.error = 'Escriba al menos 4 letras para buscar.';
                    }
                };
            });
            
            var PRAngularApp = angular.module('MunicipiosPR', ['leaflet-directive']);

            PRAngularApp.filter('numberwrap', ['$filter', function ($filter) {
              return function (input, decimals, pre, post) {
                var num = $filter('number')(input*1, decimals),
                    pre = (num&&pre)?pre+' ':'',
                    post = (num&&post)?' '+post:'';
                var resp = pre + num + post;
                    resp = resp.replace('.00','');
                return (resp!='')?resp:'n/d';
              };
            }]);


            PRAngularApp.controller('CompareCtrl', function($scope) {
            });

            PRAngularApp.controller('HomeCtrl', function($scope, $http, leafletData,leafletEvents, $window) {
                
                L.Map = L.Map.extend({
                     openPopup: function(popup) {

                     this._popup = popup;

                     return this.addLayer(popup).fire('popupopen', {
                                                      popup: this._popup
                                                      });
                     }
                });

                $scope.selected = null;

                $scope.municipios = MUNICIPIOS;

                var icon = {
                    iconUrl: '/img/pinpoints.svg',
                    iconSize:     [30, 30],
                    iconAnchor:   [15, 15],
                    popupAnchor:  [0, 15]
                };

                var markers = {};
                var markersObjList = [];
                angular.forEach(MUNICIPIOS,function(e){
                    markersObjList.push(L.marker([e.lat, e.lng]));
                    markers[e.id+'label'] = {
                        icon: {
                            type: 'div',
                            iconSize: [100, 0],
                            html: '<p class="map-label">'+e.nombre+'</p>',
                            popupAnchor:  [0, 0]
                        },
                        lat: parseFloat(e.lat-0.02),
                        lng: parseFloat(e.lng)
                    };

                    markers[e.id] = {
                        message: '<h6>Municipio de '+e.nombre+'</h6>',
                        id: parseInt(e.id),
                        lat: parseFloat(e.lat),
                        lng: parseFloat(e.lng),
                        zip: e.zip,
                        nombre: e.nombre,
                        popupOptions: {
                            closeButton: false,
                            closeOnClick: false,
                            offset: L.point(0, -5)
                        },
                        /*label: {
                            message: "Hey, drag me if you want",
                            options: {
                                directio: 'auto',
                                noHide: true,
                                offset: [12,-15]
                            }
                        },*/
                        icon: icon
                    };

                });

                //console.log(markers);

                var groupMarkers = new L.featureGroup(markersObjList);

                var regions = {
                    normal: {
                        northEast: groupMarkers.getBounds().getNorthEast(),
                        southWest: groupMarkers.getBounds().getSouthWest()
                    },
                    selected:{
                        northEast: {
                            lat: 18.5,
                            lng: -65.5
                        },
                        southWest: {
                            lat: 17.4,
                            lng: -67.3
                        }
                    }
                };

                /*$http.get("/geo/pueblos.json").success(function(data) {
                  angular.extend($scope, {
                      geojson: {
                          data: data.pueblos,
                          style: {
                                  fillOpacity: 1,
                                  fillColor: '#FFFFFF',
                                  color: '#EFF2F7', 
                                  weight: 3
                                },
                          resetStyleOnMouseout: true
                      }
                  });
                });*/

                var heights = {
                    normal: 'auto',
                    selected: '0px',
                }
                var prCenter = {
                        lat: 18.231496, 
                        lng: -66.26465999999999,
                        zoom: 9
                    };
                //$('.mapWrap').css('height', heights['normal']);

                $scope.maxbounds = regions['normal'];

                angular.extend($scope, {
                    prcenter: prCenter,
                    markers: markers,
                    layers: {
                        baselayers: {
                            xyz: {
                                name: '',
                                url: 'http://{s}.tile.openstreetmap.se/hydda/base/{z}/{x}/{y}.png',
                                type: 'xyz',
                                layerOptions:{
                                    attribution: 'Tiles courtesy of <a href="http://openstreetmap.se/" target="_blank">OpenStreetMap Sweden</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                                    showOnSelector: false
                                }
                            }
                        },
                        overlays: {
                            /*wms: {
                                name: 'sri & Stamen (XYZ)',
                                type: 'xyz',
                                visible: true,
                                url: 'http://a{s}.acetate.geoiq.com/tiles/acetate-labels/{z}/{x}/{y}.png',
                                layerOptions:{
                                    attribution: '©2012 Esri & Stamen, Data from OSM and Natural Earth',
                                    showOnSelector: false,
                                    minZoom: 2,
                                    maxZoom: 18
                                }
                            }*/
                        }
                    },
                    defaults: {
                        scrollWheelZoom: false,
                        touchZoom: false,
                        doubleClickZoom: false,
                        zoomControl: 'topleft',
                        layersControl: false
                        /*
                        tileLayer: 'http://{s}.tile.thunderforest.com/transport/{z}/{x}/{y}.png',
                        tileLayer: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                        */
                    },
                    events: {
                        markers: {
                            enable: ['click','mouseover','mouseout']//leafletEvents.getAvailableMarkerEvents()
                        }
                    }
                });

                $scope.unselect = function(){
                    if($scope.selected){
                        $scope.selected.target.closePopup();
                    }
                    $scope.selected = false;
                    $scope.selectedData = false;
                    $scope.prcenter = angular.extend(prCenter,{zoom:9});
                    $('.mapWrap').removeClass('selected');
                };

                // Markers
                $scope.$on('leafletDirectiveMap.click', function(event, args){
                    $scope.unselect();
                });

                // Markers
                $scope.$on('leafletDirectiveMarker.click', function(event, args){
                    
                    if(args.model.id){                    
                        if($scope.selected){
                            $scope.selected.target.closePopup();
                        }
                        $scope.selectedData = false;
                        $scope.selected = {model:args.model,target:args.leafletEvent.target};
                        $scope.selected.alcalde = {};
                        $scope.loading = true;
                        $http.get('/api/municipios/'+args.model.id+'/valores')
                        .then(function(d){
                            $scope.loading = false;
                            $scope.selectedData = d.data.valores;
                            $scope.selected.contratos = d.data.contratos.total;
                            $scope.selected.alcalde = d.data.alcalde;
                        });

                        $('.mapWrap').addClass('selected');

     //                   $scope.maxbounds = regions['selected'];
                        $scope.prcenter={
                            lat: args.leafletEvent.target._latlng.lat, 
                            lng: args.leafletEvent.target._latlng.lng,
                            zoom: 10
                        };;
                        args.leafletEvent.target.openPopup();
                    }
                });
                $scope.$on('leafletDirectiveMarker.mouseover', function(event, args){
                    if(args.model.id){  
                        args.leafletEvent.target.openPopup();
                    }
                });
                $scope.$on('leafletDirectiveMarker.mouseout', function(event, args){
                    if(args.model.id){  
                        if($scope.selected){
                            if($scope.selected.model.id != args.model.id){
                                args.leafletEvent.target.closePopup();
                            }
                        } else {
                            args.leafletEvent.target.closePopup();
                        }
                    }
                });


            });

        function initMap(){
            angular.bootstrap(angular.element(".mapMunicipality")[0], ['MunicipiosPR']);
        }

        if(typeof(L)!="undefined" && window.innerWidth>768){
            initMap();
        }

        angular.bootstrap(angular.element("#searchContainer")[0], ['SearchMunicipiosPR']);


}

$(function(){
    
    //resize
    function resizeCallback (){
        if(window.innerWidth<768){
            $('body').addClass('body-fixed');
            $('.includeExternal nav').addClass('fixed');
            $( window ).off('scroll');
        } else {
            $('body').removeClass('body-fixed');
            $('.includeExternal nav').removeClass('fixed');
            $( window ).on('scroll',function() {
                if($(this).scrollTop()>460){
                    $('.includeExternal nav').addClass('fixed');
                } else {
                    $('.includeExternal nav').removeClass('fixed');
                }
            });
        }
    }
    if($('body.master-body').size()==1){
        resizeCallback();

        $( window ).resize(resizeCallback);
        
    }
    
    $('.periodo_selector').on('change',function(){
        var val = $(this).val();
        if(val && val !=''){
          window.location = window.location.origin + window.location.pathname + '?periodo=' + val;
        }
    });

    //tooltips
    $('[data-toggle="tooltip"]').tooltip();

    $('.mobile-nav').on('click',function(){
        $('.includeExternal nav').toggleClass('active');
    });

});

var DATATABLE_I18N = {
    "sProcessing":     "Procesando...",
    "sLengthMenu":     "Mostrar _MENU_ registros",
    "sZeroRecords":    "No se encontraron resultados",
    "sEmptyTable":     "Ningún dato disponible en esta tabla",
    "sInfo":           "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    "sInfoEmpty":      "Mostrando registros del 0 al 0 de un total de 0 registros",
    "sInfoFiltered":   "(filtrado de un total de _MAX_ registros)",
    "sInfoPostFix":    "",
    "sSearch":         "Buscar:",
    "sUrl":            "",
    "sInfoThousands":  ",",
    "sLoadingRecords": "Cargando...",
    "oPaginate": {
        "sFirst":    "Primero",
        "sLast":     "Último",
        "sNext":     "Siguiente",
        "sPrevious": "Anterior"
    },
    "oAria": {
        "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
        "sSortDescending": ": Activar para ordenar la columna de manera descendente"
    }
};