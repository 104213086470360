

//From api/RankingController
window.municipiosEmbed = window.municipiosEmbed || {};

window.municipiosEmbed.initializedRanking = false;
window.municipiosEmbed.initializedCharts = false;

window.municipiosEmbed.html = {};

window.municipiosEmbed.html['municipio'] =  
            '<div class="boxItem {colorClass}">'+
                 '<div class="boxItem-wrap">'+
                  '<div class="boxItemList-name">'+
                    '<h6>{title}</h6>'+
                    '<hr>'+
                    '<a href="#{idModal}" data-target="#{idModal}" role="button" data-toggle="modal" href="#" class="boxItemList-embedLink">'+
                          '<span>'+
                            '<i class="icon icon-12 flaticon solid left-2"></i>'+
                            '<i class="icon icon-12 flaticon solid right-2"></i>'+
                          '</span>'+
                          'Embed'+
                        '</a>'+
                  '</div>'+
                  '<div class="boxItemList-alt">'+
                    '<h5>{top1.valor} <span>Municipio de {top1.nombre}</span></h5>'+
                  '</div>'+
                  '<div class="boxItemList-body">'+
                    '<ul>'+
                      '<li>'+
                        '<strong>Municipio de {top2.nombre}</strong>'+
                        '<span>{top2.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>Municipio de {top3.nombre}</strong>'+
                        '<span>{top3.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>Municipio de {top4.nombre}</strong>'+
                        '<span>{top4.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>Municipio de {top5.nombre}</strong>'+
                        '<span>{top5.valor}</span>'+
                      '</li>'+
                    '</ul>'+
                  '</div>'+
                  '<div class="boxItemList-actions">'+
                      '<a href="{ver_todos_link}" target="_parent">Ver todo</a>'+
                  '</div>'+
                  '<div class="boxItemList-foot">'+
                    '<p>* {obj.fuente}</p>'+
                  '</div>'+
                  '{copy}'+
                '</div>'+
            '</div>';

window.municipiosEmbed.html['contrato'] =   '<div class="boxItem {colorClass}">'+
                 '<div class="boxItem-wrap">'+
                  '<div class="boxItemList-name">'+
                    '<h6>{title}</h6>'+
                    '<hr>'+
                    '<a href="#{idModal}" data-target="#{idModal}" role="button" data-toggle="modal" href="#" class="boxItemList-embedLink">'+
                          '<span>'+
                            '<i class="icon icon-12 flaticon solid left-2"></i>'+
                            '<i class="icon icon-12 flaticon solid right-2"></i>'+
                          '</span>'+
                          'Embed'+
                        '</a>'+
                  '</div>'+
                  '<div class="boxItemList-alt">'+
                    '<h5>{top1.valor} <span>{top1.nombre}</span></h5>'+
                  '</div>'+
                  '<div class="boxItemList-body">'+
                    '<ul>'+
                      '<li>'+
                        '<strong>{top2.nombre}</strong>'+
                        '<span>{top2.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>{top3.nombre}</strong>'+
                        '<span>{top3.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>{top4.nombre}</strong>'+
                        '<span>{top4.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>{top5.nombre}</strong>'+
                        '<span>{top5.valor}</span>'+
                      '</li>'+
                    '</ul>'+
                  '</div>'+
                  '<div class="boxItemList-actions">'+
                      '<a href="{ver_todos_link}" target="_parent">Ver todo</a>'+
                  '</div>'+
                  '<div class="boxItemList-foot">'+
                    '<p>* {obj.fuente}</p>'+
                  '</div>'+
                  '{copy}'+
                '</div>'+
            '</div>';

window.municipiosEmbed.html['alcalde'] =    
                '<div class="boxItem {colorClass}">'+
                 '<div class="boxItem-wrap">'+
                  '<div class="boxItemList-name">'+
                    '<h6>{title}</h6>'+
                    '<hr>'+
                    '<a href="#{idModal}" data-target="#{idModal}" role="button" data-toggle="modal" href="#" class="boxItemList-embedLink">'+
                          '<span>'+
                            '<i class="icon icon-12 flaticon solid left-2"></i>'+
                            '<i class="icon icon-12 flaticon solid right-2"></i>'+
                          '</span>'+
                          'Embed'+
                        '</a>'+
                  '</div>'+
                  '<div class="boxItemList-alt">'+
                    '<h5>'+
                      '{top1.valor}'+
                      '<span>'+
                        '<a href="{base_path}/alcaldes/{top1.id}">'+
                        '<figure class="wrapIcon wrapIcon--xs">'+
                          '<img src="{top1.avatar}"/>'+
                        '</figure>'+
                          '{top1.nombre}'+
                        '</a>'+
                      '</span>'+
                    '</h5>'+
                  '</div>'+
                  '<div class="boxItemList-body">'+
                    '<ul>'+
                      '<li>'+
                        '<strong>{top2.nombre}</strong>'+
                        '<span>{top2.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>{top3.nombre}</strong>'+
                        '<span>{top3.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>{top4.nombre}</strong>'+
                        '<span>{top4.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>{top5.nombre}</strong>'+
                        '<span>{top5.valor}</span>'+
                      '</li>'+
                    '</ul>'+
                  '</div>'+
                   '<div class="boxItemList-actions">'+
                        '<a href="{ver_todos_link}" target="_parent">Ver todo</a>'+
                    '</div>'+
                  '<div class="boxItemList-foot">'+
                    '<p>* {obj.fuente}</p>'+
                  '</div>'+
                  '{copy}'+
                '</div>'+
              '</div>';

window.municipiosEmbed.html['wrap'] = '<div class="boxItem {colorClass}">'+
                  '<div class="boxItem-wrap">'+
                  '{msg}'+
                  '</div>'+
                  '</div>';

// charts
window.municipiosEmbed.html['totales'] =  '<div class="heading heading--s">'+
                    '<h6>{title} - {subtitle}</h6>'+
                       '<a href="#{idModal}" data-target="#{idModal}" role="button" data-toggle="modal" href="#" class="chart-embedLink">'+
                          '<span>'+
                            '<i class="icon icon-12 flaticon solid left-2"></i>'+
                            '<i class="icon icon-12 flaticon solid right-2"></i>'+
                          '</span>'+
                          'Embed'+
                        '</a>'+
                  '</div>'+
                  '<div class="graph graph--16_9">'+
                  '<div class="municipio-embed-chart-container"></div>'+
                  '</div>';

window.municipiosEmbed.html['cantidad'] =   
                  '<div class="heading heading--s">'+
                    '<h6>{title} - {subtitle}</h6>'+
                       '<a href="#{idModal}" data-target="#{idModal}" role="button" data-toggle="modal" href="#" class="chart-embedLink">'+
                          '<span>'+
                            '<i class="icon icon-12 flaticon solid left-2"></i>'+
                            '<i class="icon icon-12 flaticon solid right-2"></i>'+
                          '</span>'+
                          'Embed'+
                        '</a>'+
                  '</div>'+
                  '<div class="graph graph--16_9">'+
                  '<div class="municipio-embed-chart-container"></div>'+
                  '</div>';

window.municipiosEmbed.html['indicador'] = 
                  '<div class="heading heading--s">'+
                    '<h6>{indicador.nombre}<span class="info-tooltip-container"><i class="icon icon-16 flaticon solid info-2" data-toggle="tooltip" data-placement="bottom" title="{indicador.detalle}"></i></span></h6>'+
                       '<a href="#{idModal}" data-target="#{idModal}" role="button" data-toggle="modal" href="#" class="chart-embedLink">'+
                          '<span>'+
                            '<i class="icon icon-12 flaticon solid left-2"></i>'+
                            '<i class="icon icon-12 flaticon solid right-2"></i>'+
                          '</span>'+
                          'Embed'+
                        '</a>'+
                  '</div>'+
                  '<div class="graph graph--16_9">'+
                  '<div class="municipio-embed-chart-indicador-container"></div>'+
                  '</div>'+
                  '<p class="graphSource"><strong>Fuente:</strong> {indicador.fuente}</p>';

window.municipiosEmbed.html['comparetwo'] = 
                '<div class="row">'+
                  '<div class="col8">'+

                    '<div class="graphBar graphBar--{data1.clase}">'+
                      '<span class="graphBar-valor graphBar-valor--price">{data1.valorf}</span>'+
                      '<span class="graphBar-shift">'+
                      '<em></em>'+
                    '</span>'+
                      '<span class="graphBar-bar">'+
                        '<span style="height: {data1.porcentaje}%;"></span>'+
                      '</span>'+
                      '<span class="graphBar-quote">{data1.periodo}</span>'+
                    '</div>'+

                  '</div>'+
                  '<div class="col8">'+
                    
                    '<div class="graphBar graphBar--{data2.clase}">'+
                      '<span class="graphBar-valor graphBar-valor--price">{data2.valorf}</span>'+
                    '<span class="graphBar-shift">'+
                      '<em><i class="icon icon-16"></i></em>'+
                    '</span>'+
                      '<span class="graphBar-bar">'+
                        '<span style="height: {data2.porcentaje}%;"></span>'+
                      '</span>'+
                      '<span class="graphBar-quote">{data2.periodo}</span>'+
                    '</div>'+

                  '</div>'+
                '</div>';

window.municipiosEmbed.html['modal'] = '<div aria-hidden="true" style="display: none;" class="modal fade" id="{id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">'+
      '<div class="modal-dialog" role="document">'+
        '<div class="modal-content">'+
          '<div class="modal-header">'+
            '<h6>Código para insertar</h6>'+
          '</div>'+
          '<div class="modal-body">'+
            '<textarea rows="4" class="form-control">{code}</textarea>'+
          '</div>'+
          '<div class="modal-footer">'+
            '<ul>'+
              '<li><a class="btn btn--primary" data-dismiss="modal">Cerrar</a></li>'+
              //'<li><a href="{generador_link}" class="btn btn--default pull-right">Embeber avanzado</a></li>'+
            '</ul>'+
          '</div>'+
        '</div>'+
      '</div>'+
    '</div>';

window.municipiosEmbed.html['copy'] = '<div class="boxItemList-copyright">'+
                        '<p><a href="http://www.puertoricodecide2016.com/" target="_parent">Puerto Rico Decide 2016</a></p>'+
                      '</div>';

//NANO template engine from: https://github.com/trix/nano
window.municipiosEmbed.nano = function(template, data) {
  return template.replace(/\{([\w\.]*)\}/g, function(str, key) {
    var keys = key.split("."), v = data[keys.shift()];
    for (var i = 0, l = keys.length; i < l; i++) v = v[keys[i]];
    return (typeof v !== "undefined" && v !== null) ? v : "";
  });
};

window.municipiosEmbed.getRandomClass = function(){
  var classes = ['boxItem--primary','boxItem--secondary','boxItem--tertiary','boxItem--quaternary'];
  return classes[Math.floor(Math.random()*classes.length)];
};

window.municipiosEmbed.unique = function(list) {
  var result = [];
  $.each(list, function(i, e) {
    if ($.inArray(e, result) == -1) result.push(e);
  });
  return result;
};

window.municipiosEmbed.initRanking = function(){

    window.municipiosEmbed.initializedRanking = true;

  var embedsIds = [];
  var embeds = {};
  var wrapTpl = window.municipiosEmbed.html['wrap'];
  var wrapData = {
    msg: '<span class="municipio-embed-loader">cargando...</span>'
  };

    //Embed de rankings
    $('.municipio-embed-ranking').each(function(){
        
    var container = $(this);
    var id = container.data('id');
        var idModal = 'municipio-embed-ranking-'+id+'-modal';
    var colorClass = container.data('class');
        var copy = container.data('copy');
    copy = (copy)?window.municipiosEmbed.html['copy']:'';
    colorClass = (colorClass)?colorClass:window.municipiosEmbed.getRandomClass();
    
    //loading
    wrapData.colorClass = (colorClass)?colorClass:window.municipiosEmbed.getRandomClass();
    container.html(window.municipiosEmbed.nano(wrapTpl, wrapData));

    if(window.municipiosEmbed.hasModal){
      var modalTpl = window.municipiosEmbed.html['modal'];
      var modalData = {
        id: idModal,
        generador_link: window.municipiosRankingURL + '/api/embed',
        code: '<div id="prmunicipios-ranking-'+id+'" data-pym-src="'+window.municipiosRankingURL+'/api/embed/ranking/'+id+'?class='+colorClass+'"></div><script src="https://cdnjs.cloudflare.com/ajax/libs/pym/0.4.5/pym.min.js" type="text/javascript"></script>',
      };
      $('body').append(window.municipiosEmbed.nano(modalTpl, modalData));
    }

    embedsIds.push(id);
    embeds[id] = {
      id: id,
      idModal: idModal,
      copy: copy,
      container: container,
      colorClass: colorClass
    };

    });

  if(embedsIds.length){

    $.getJSON(window.municipiosRankingURL+'/api/ranking/'+embedsIds.join(','))
    .then(function(datas){    

      $.each(datas,function(ix,d){
        
        if(d){

          var data = {
            title: d.title,
            obj: d.obj,
            indicador: d.indicador,
            idModal: embeds[ix].idModal,
            copy: embeds[ix].copy,
            colorClass: embeds[ix].colorClass,
            ver_todos_link: window.municipiosRankingURL + '/rankings/'+ ix,
            base_path: window.municipiosRankingURL
          };
          if(data.obj.fuente){
            data.obj.fuente = 'Fuente: '+ data.obj.fuente;
          }
          if(d.data.length>0){
            try{
              d.data = window.municipiosEmbed.numberFormatData(d);

              $.each(d.data,function(i,e){
                if(e.foto){
                  e.avatar = data.base_path+'/uploads/alcaldes/'+e.foto;
                } else {
                  e.avatar = data.base_path+'/img/avatar.gif';
                }
                data['top'+(i+1)] = e;
              });
              var tpl = window.municipiosEmbed.html[d.obj.entidad];
              embeds[ix].container.html(window.municipiosEmbed.nano(tpl, data));       
            }catch(e){
              wrapData.msg = '<p class="municipio-embed-error">Ocurrió un error</p>';
              embeds[ix].container.html(window.municipiosEmbed.nano(wrapTpl, wrapData));
            }
          } else {
            wrapData.msg = '<p class="municipio-embed-error">No hay información disponible</p>';
            embeds[ix].container.html(window.municipiosEmbed.nano(wrapTpl, wrapData));
          }
          
        } else {
          console.error('Error al cargar la info de ', ix);
          embeds[ix].container.remove();
        }
        
        //iframe height
        if(typeof pymChild === 'undefined'){
        }else{
          pymChild.sendHeight();
        }

      });
      
    })
    .fail(function(d){
      console.error(d);
    });

  }

};

window.municipiosEmbed.initChart = function(){

  window.municipiosEmbed.initializedChart = true;

  //custom colors
  window.municipiosEmbed.colors = d3.scale.ordinal()
    .range(["#f49822", "#2179b2" , "#6d636d", "#979797"])

  if($('.municipio-embed-chart[data-municipios]').size()>0){
    window.municipiosEmbed.initIndicadorChart(); 
  }

  if($('.municipio-embed-chart[data-tipo]').size()>0){
    window.municipiosEmbed.initContratosChart(); 
  }

};

window.municipiosEmbed.initIndicadorChart = function(){

  var embeds = {};
  var municipiosIDS = [];
  var indicadoresIDS = [];

  var wrapTpl = window.municipiosEmbed.html['wrap'];
  var wrapData = {
    msg: '<span class="municipio-embed-loader">cargando...</span>'
  };

  //Embed de gráficos de indicadores
  $('.municipio-embed-chart[data-indicador]').each(function(i,e){
    
    var container = $(this);
    //loading
    container.html(window.municipiosEmbed.nano(wrapTpl, wrapData));
    
    var municipiosID = ''+container.data('municipios');
    var indicadorID = container.data('indicador');
    var chartType = container.data('charttype');
    chartType = (chartType)?chartType:'line';

    municipiosIDS = municipiosIDS.concat(municipiosID.split(','));
    indicadoresIDS.push(indicadorID);

    var id = 'prmunicipios-chart-'+i;
    var idModal = id+'-modal';

    if(window.municipiosEmbed.hasModal){
      var modalTpl = window.municipiosEmbed.html['modal'];
      var modalData = {
        id: idModal,
        code: '<div id="'+id+'" data-pym-src="'+window.municipiosRankingURL+'/api/embed/chart/?indicador_id='+indicadorID+'&municipios='+municipiosID+'&charttype='+chartType+'"></div><script src="https://cdnjs.cloudflare.com/ajax/libs/pym/0.4.5/pym.min.js" type="text/javascript"></script>',
        //code: '<iframe src="'+window.municipiosRankingURL+'/api/embed/ranking/'+id+'?class='+colorClass+'" frameborder="0" height="400" width="100%"></iframe>'
      };
      $('body').append(window.municipiosEmbed.nano(modalTpl, modalData));
    }

    embeds[municipiosID+'_'+indicadorID] = {
      idModal: idModal,
      indicadorID: indicadorID,
      municipioIDS: municipiosID.split(','),
      container: container,
      chartType: chartType,
      colors: window.municipiosEmbed.colors
    };

  });

  municipiosIDS = window.municipiosEmbed.unique(municipiosIDS);

  if(municipiosIDS.length && indicadoresIDS.length){

    $.getJSON(window.municipiosRankingURL+'/api/municipios/'+municipiosIDS.join(',')+'/indicadores/'+indicadoresIDS.join(','))
    .then(function(response){
      var tpl = window.municipiosEmbed.html['indicador'];

      $.each(embeds,function(i,e){

        if(response.indicadores[e.indicadorID] && response.data[e.indicadorID]){

          //Setup TPL
          var tplInfo = {
            indicador: response.indicadores[e.indicadorID],
            idModal: e.idModal
          };
          e.container.html(window.municipiosEmbed.nano(tpl, tplInfo));

          //Chart container
          var idChart = 'municipio-embed-chart-indicador-container-'+i.replace(',','-','g');
          e.container.find('.municipio-embed-chart-indicador-container').attr('id',idChart);

          var graphData = [];

          var filteredData = response.data[e.indicadorID].filter(function(dat){
              return ($.inArray(''+dat.municipio_id,e.municipioIDS) != -1);
          });          

          var nested={};
          $.each(filteredData, function(i, el) {
              var per = (el.periodo)?el.periodo:' ';
              if (!nested[per]) {
                  nested[per] = [];
              }
              nested[per].push(el);
          });

          var max = d3.max(filteredData,function(e){return parseFloat(e.valor);});
          var min = d3.min(filteredData,function(e){return parseFloat(e.valor);});

          var valuesLabel = [];
          $.each(nested,function(ix,per){
            var item = {periodo:parseInt(ix)};
            $.each(per,function(ixi,valor){
              var muniName = response.municipios[valor.municipio_id];
              valuesLabel.push(muniName);
              item[muniName] = parseInt(valor.valor);
            });
            graphData.push(item);
          });

          valuesLabel = window.municipiosEmbed.unique(valuesLabel);


          switch(e.chartType){
            case 'line':
              window.municipiosEmbed.renderC3LineChart(e,graphData,valuesLabel,idChart,min,max);
              break;
            case 'bar':
              window.municipiosEmbed.renderC3BarChart(e,graphData,valuesLabel,idChart,false,min,max);
              break;
            case 'barh':
              window.municipiosEmbed.renderC3BarChart(e,graphData,valuesLabel,idChart,true,min,max);
              break;
            case 'comparetwo':
              window.municipiosEmbed.renderCompareTwoChart(filteredData,idChart, e.indicadorID);
              break;
          }

          var infoTooltip = e.container.find('.info-tooltip-container i').first();

          if( window.municipiosEmbed.hasTooltip && infoTooltip && infoTooltip.attr('title')!='' ){
            infoTooltip.tooltip();
          } else {
            infoTooltip.hide();
          }

          //iframe height
          if(typeof pymChild === 'undefined'){
          }else{
            pymChild.sendHeight();
          }

        } else {
          console.log('sin datos');
        }

      });

    });

  }

};

window.municipiosEmbed.renderCompareTwoChart = function(filteredData,idChart, indicadorID){

  var tpl = window.municipiosEmbed.html['comparetwo'];

  var val1 = (filteredData[0])?
    {
      periodo: filteredData[0].periodo,
      valor: parseFloat(filteredData[0].valor),
      valorf: window.municipiosEmbed.numberFormatDataByIndicador(indicadorID,filteredData[0].valor)
    }:{
      periodo:'',
      valor:0
    };

  var val2 = (filteredData[1])?
    {
      periodo: filteredData[1].periodo,
      valor: parseFloat(filteredData[1].valor),
      valorf: window.municipiosEmbed.numberFormatDataByIndicador(indicadorID,filteredData[1].valor)
    }:{
      periodo:'',
      valor:0
    };

    if(val1.valor>=val2.valor){
      val1.porcentaje = 100;
      val2.porcentaje = Math.floor((val2.valor*100)/val1.valor);
      val1.clase='success';
      val2.clase='error';
    } else {
      val2.porcentaje = 100;
      val1.porcentaje = Math.floor((val1.valor*100)/val2.valor);
      val2.clase='success';
      val1.clase='error';
    }

  var data = {
    data1: val1,
    data2: val2
  };

  $('#'+idChart).parent().attr('class','').html(window.municipiosEmbed.nano(tpl, data));

}

window.municipiosEmbed.renderC3LineChart = function(e,graphData,valuesLabel,idChart,min,max){
      var colorsTypes = {};
      var valuesTypes = {};
      $(valuesLabel).each(function(ix,el){
        valuesTypes[el] = 'area-spline';
        colorsTypes[el] = (valuesLabel.length==1)?e.colors(Math.random()):e.colors(ix);
      });

      e.chart = c3.generate({
          legend: {
              show: (valuesLabel.length>1)?true:false,
          },
          bindto: '#'+idChart,
           padding: {
              top: 10,
              right: 20,
              bottom: 0
          },
          data: {
              json: graphData,
              keys: {
                  x: 'periodo', // it's possible to specify 'x' when category axis
                  value: valuesLabel
              },
              types: valuesTypes,
              colors: colorsTypes,
//                  type: e.chartType
          },
          axis: {
              x: {
//                      type: 'category',
                  padding: {
                    left: 0.5,
                    right: 0.5,
                  },
                  tick: {

                    format:function(x){
                      return (x == Math.floor(x)) ? x: "";
                    } 
                  }
              },
              y:{
                 min:0,
                 tick: {
                    values: (min<0)?[min, 0, max]:[0, max],
                    format:function(y){
                      if(y==0) return window.municipiosEmbed.numberFormatDataByIndicador(e.indicadorID,0);
                      return  window.municipiosEmbed.numberFormatDataByIndicador(e.indicadorID,y);

                    }
                  }
              }
          }
      });

};

window.municipiosEmbed.renderC3BarChart = function(e,graphData,valuesLabel,idChart,rotate,min,max){
      var colorsTypes = {};
      var valuesTypes = {};
      $(valuesLabel).each(function(ix,el){
        valuesTypes[el] = 'area-spline';
        if(e.indicadorID==8){
            colorsTypes[el] = '#ccc';
        } else {
          colorsTypes[el] = (valuesLabel.length==1)?e.colors(Math.random()):e.colors(ix);
        }
      });

      var tooltipData = {};
      if(e.indicadorID==8){
        tooltipData = {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
            var $$ = this, config = $$.config,
              titleFormat = config.tooltip_format_title || defaultTitleFormat,
              nameFormat = config.tooltip_format_name || function (name) { return name; },
              valueFormat = config.tooltip_format_value || defaultValueFormat,
              text, i, title, value, name, bgcolor;
            for (i = 0; i < d.length; i++) {
                if (! (d[i] && (d[i].value || d[i].value === 0))) { continue; }

                if (! text) {
                    title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                    text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
                }

                name = nameFormat(d[i].name);
                value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
                bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

                text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                text += "<td class='name'>" + name + "</td>";
                text += "<td class='value'>" + value + "</td>";
                text += "</tr>";
            }
            return text + "</table>";
          }
        }
      }

      e.chart = c3.generate({
          legend: {
              show: (valuesLabel.length>1)?true:false,
          },
          bindto: '#'+idChart,
           padding: {
              top: 10,
              right: 20,
              bottom: 0
          },
          data: {
              json: graphData,
              keys: {
                  x: 'periodo', // it's possible to specify 'x' when category axis
                  value: valuesLabel
              },
              //types: valuesTypes,
              colors: colorsTypes,
              type: 'bar',
              color: function (color, d) {
                  if(e.indicadorID==8){
                      return (d.value<0)?'#FA0000':'#009B48';
                  }

                  // d will be 'id' when called for legends
                  return color;
              }
          },
          tooltip: tooltipData,
          axis: {
              rotated: (rotate===true),
              x: {
                  type: 'category'
              },
              y:{
                 tick: {
                   values: (min<0)?[min, max]:[0, max],
                    format:function(y){
                      if(y==0) return window.municipiosEmbed.numberFormatDataByIndicador(e.indicadorID,0);
                      return  window.municipiosEmbed.numberFormatDataByIndicador(e.indicadorID,y);

                    }
                  }
              }
          }
      });

};


window.municipiosEmbed.initContratosChart = function(){
  //Embed de gráficos de contratos
  $('.municipio-embed-chart[data-tipo]').each(function(){
    var container = $(this),
      idMunicipio = container.data('municipio'),
      periodo = container.data('periodo'),
      tipo = container.data('tipo'),
      params = {};

      if(periodo){
        params.periodo = periodo;
      }

      if(idMunicipio){
        params.municipio = idMunicipio;
      }

    container.html('<span class="municipio-embed-loader">cargando...</span>');
    
    $.getJSON(window.municipiosRankingURL+'/api/charts/contratos/'+tipo+'?'+jQuery.param(params))
    .then(function(d){
      var idChart = 'municipio-embed-chart-container-'+idMunicipio+'-'+tipo;
      var data = {
        title: d.title + ' - Período: ' + d.periodo.label,
        subtitle: (d.municipio)?'Municipio de '+d.municipio.nombre:'Puerto Rico'
      };
      if(d.data.length>0){
        try{

          var idModal = idChart + '-modal';

          if(window.municipiosEmbed.hasModal){
            var modalTpl = window.municipiosEmbed.html['modal'];
            var modalData = {
              id: idModal,
              code: '<div id="'+idChart+'" data-pym-src="'+window.municipiosRankingURL+'/api/embed/chart-contratos/?periodo='+periodo+'&municipio='+idMunicipio+'&tipo='+tipo+'"></div><script src="https://cdnjs.cloudflare.com/ajax/libs/pym/0.4.5/pym.min.js" type="text/javascript"></script>',
              //code: '<iframe src="'+window.municipiosRankingURL+'/api/embed/ranking/'+id+'?class='+colorClass+'" frameborder="0" height="400" width="100%"></iframe>'
            };
            $('body').append(window.municipiosEmbed.nano(modalTpl, modalData));
            data.idModal = idModal;
          }


          var tpl = window.municipiosEmbed.html[tipo];
          container.html(window.municipiosEmbed.nano(tpl, data));
          container.find('.municipio-embed-chart-container').attr('id',idChart);
          var chart = c3.generate({
            bindto: '#'+idChart,
              data: {
                    json: d.data,
                    keys: {
                        x: 'year',
                        value: ['total'],
                    },
                    types: {'total':'area-spline'},
                    colors: {'total': window.municipiosEmbed.colors(Math.random())}
                },
                axis: {
                  x: {
                      label: 'Años',
                      //type: 'category'
                      padding: {
                        left: 0.3,
                        right: 0.3,
                      },
                      tick: {
                        format:function(x){
                           return (x == Math.floor(x)) ? x: "";
                        } 
                      }
                  },
                  y:{
                    label: (d.type=='cantidad')?'Contratos':'Dólares',
                    min:0,
                    tick: {
                        format:function(x){
                           return (d.type=='cantidad')?window.municipiosEmbed.numberFormat(x,0,'',' cont.'):window.municipiosEmbed.numberFormat(x,0,'$','')
                        } 
                      }
                  }

              },
              legend: {
                  show: false
              }
          });

          //iframe height
          if(typeof pymChild === 'undefined'){
          }else{
            pymChild.sendHeight();
          }

        }catch(e){
          container.html('<p class="municipio-embed-error">Ocurrió un error</p>');
          console.log(e);       
        }
      } else {
        container.html('<p class="municipio-embed-error">No hay información disponible</p>');
      }

    })
    .fail(function(d){
      console.error('Error al cargar la info de embed de '+idMunicipio+'-'+tipo);
      container.remove();
    });
  });
}

//Rankings
if ((document.getElementsByClassName('municipio-embed-ranking').length>0) && !window.jQuery) {
    console.error('Jquery es requerido para utilizar el plugin de embed de Municipios');
} else {
    $(function(){
        if(!window.municipiosEmbed.initializedRanking) {
      window.municipiosEmbed.hasModal = (typeof $().modal == 'function');
      window.municipiosEmbed.initRanking();
        }
    });
}

//Charts
if ((document.getElementsByClassName('municipio-embed-chart').length>0) && (!window.d3 || !window.c3 || !window.jQuery) ) {
    console.error('Jquery, D3js y C3js son requerido para utilizar el plugin de embed de gráficos.');
} else {
     $(function(){
    if(!window.municipiosEmbed.initializedChart) {
      window.municipiosEmbed.hasModal = (typeof $().modal == 'function');
      window.municipiosEmbed.hasTooltip = (typeof $().tooltip == 'function');
      window.municipiosEmbed.initChart();
    }
  });
}

/*

PRAngularApp.filter('numberwrap', ['$filter', function ($filter) {
  return function (input, decimals, pre, post) {
    var num = $filter('number')(input*1, decimals),
        pre = (num&&pre)?pre+' ':'',
        post = (num&&post)?' '+post:'';
    var resp = pre + num + post;
        resp = resp.replace('.00','');
    return (resp!='')?resp:'n/d';
  };
}]);
*/

window.municipiosEmbed.numberFormatDataByIndicador = function(id,valor){
    var res = [2,'$',''];
    switch(parseInt(id)){
      case 1:
        res = [0,'','hab.'];
      break;
      case 3:
      case 5:
        res = [2,'','%'];
      break;
      case 4:
      case 7:
        res = [0,'','emp.'];
      break;
    }

    return window.municipiosEmbed.numberFormat(valor,res[0],res[1],res[2])

};

window.municipiosEmbed.numberFormatData = function(ranking){
  var resp = [];

  var dict = {
    2:[2,'','%'],
    3:[2,'','%'],
    7:[0,'','propiedades'],
    8:[0,'','períodos'],
    21:[0,'','períodos']
  };

  $(ranking.data).each(function(i,e){
    var wraps = [2,'$',''];
    var formatted = false;

    if(ranking.indicador){
      e.valor =  window.municipiosEmbed.numberFormatDataByIndicador(ranking.indicador.id,e.valor);
      formatted = true;
    }

    if(!formatted && ranking.obj && ranking.obj.id){
        wraps = (dict[ranking.obj.id])?dict[ranking.obj.id]:[2,'$',''];
        e.valor = window.municipiosEmbed.numberFormat(e.valor,wraps[0],wraps[1],wraps[2]);
    }
    resp.push(e)
  });

  return resp;

};


window.municipiosEmbed.numberFormat = function(number, decimals, pre, post){
  if(window.jQuery && window.jQuery.number ){
    var num = window.jQuery.number( number, decimals, '.', ',' );
        //num = (num==0)?'':num;
        pre = (num&&pre)?pre+' ':'',
        post = (num&&post)?' '+post:'';
    var resp = pre + num + post;
        resp = resp.replace('.00','');
    return (resp!='')?resp:'n/d';
  }else{
    return number;
  }
}

/**
 * RANKINGS LEGISLATIVOS
 *************************************************************************************************/

window.senadoresEmbed = window.senadoresEmbed || {};

window.senadoresEmbed.initializedRanking = false;
window.senadoresEmbed.initializedCharts = false;

window.senadoresEmbed.html = {};
window.senadoresEmbed.html['wrap'] = '<div class="boxItem {colorClass}">'+
                  '<div class="boxItem-wrap">'+
                  '{msg}'+
                  '</div>'+
                  '</div>';

window.senadoresEmbed.html['modal'] = '<div aria-hidden="true" style="display: none;" class="modal fade" id="{id}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">'+
      '<div class="modal-dialog" role="document">'+
        '<div class="modal-content">'+
          '<div class="modal-header">'+
            '<h6>Código para insertar</h6>'+
          '</div>'+
          '<div class="modal-body">'+
            '<textarea rows="4" class="form-control">{code}</textarea>'+
          '</div>'+
          '<div class="modal-footer">'+
            '<ul>'+
              '<li><a class="btn btn--primary" data-dismiss="modal">Cerrar</a></li>'+
              //'<li><a href="{generador_link}" class="btn btn--default pull-right">Embeber avanzado</a></li>'+
            '</ul>'+
          '</div>'+
        '</div>'+
      '</div>'+
    '</div>';

window.senadoresEmbed.html['senador'] =    
                '<div class="boxItem {colorClass}">'+
                 '<div class="boxItem-wrap">'+
                  '<div class="boxItemList-name">'+
                    '<h6>{title}</h6>'+
                    '<hr>'+
                    '<a href="#{idModal}" data-target="#{idModal}" role="button" data-toggle="modal" href="#" class="boxItemList-embedLink">'+
                          '<span>'+
                            '<i class="icon icon-12 flaticon solid left-2"></i>'+
                            '<i class="icon icon-12 flaticon solid right-2"></i>'+
                          '</span>'+
                          'Embed'+
                        '</a>'+
                  '</div>'+
                  '<div class="boxItemList-alt">'+
                    '<h5>'+
                      '{top1.valor}'+
                      '<span>'+
                        '<a href="{base_path}/legislativo/{top1.id}" target="_parent">'+
                        '<figure class="wrapIcon wrapIcon--s">'+
                          '<img src="{top1.avatar}"/>'+
                        '</figure>'+
                          '{top1.nombre}'+
                        '</a>'+
                      '</span>'+
                    '</h5>'+
                  '</div>'+
                  '<div class="boxItemList-body">'+
                    '<ul>'+
                      '<li>'+
                        '<strong>{top2.nombre}</strong>'+
                        '<span>{top2.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>{top3.nombre}</strong>'+
                        '<span>{top3.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>{top4.nombre}</strong>'+
                        '<span>{top4.valor}</span>'+
                      '</li>'+
                      '<li>'+
                        '<strong>{top5.nombre}</strong>'+
                        '<span>{top5.valor}</span>'+
                      '</li>'+
                    '</ul>'+
                  '</div>'+
                   '<div class="boxItemList-actions">'+
                        '<a href="{ver_todos_link}" target="_parent">Ver todo</a>'+
                    '</div>'+
                  '<div class="boxItemList-foot">'+
                    '<p>* {obj.fuente}</p>'+
                  '</div>'+
                  '{copy}'+
                '</div>'+
              '</div>';
window.senadoresEmbed.html['copy'] = '<div class="boxItemList-copyright">'+
                        '<p><a href="http://www.puertoricodecide2016.com/" target="_parent">Puerto Rico Decide 2016</a></p>'+
                      '</div>';
window.senadoresEmbed.numberFormatData = function(ranking){
  var resp = [];
  var ind = {
    'sueldo':[2,'$','']
    ,'sueldos':[2,'$','']
    ,2:[2,'$','']
    ,1:[0,'','propiedad(es)']
    ,'mandatos':[0,'','período(s)']
    ,4:[0,'$','']
  }
  if(!isNaN(ranking.indicador))
    var wrap = [0,'',''];
  else
    var wrap = ind[ranking.indicador];
  $(ranking.data).each(function(i,e){
    e.valor = window.senadoresEmbed.numberFormat(e.valor,wrap[0],wrap[1],wrap[2]);
    resp.push(e)
  });
  return resp;
};

//NANO template engine from: https://github.com/trix/nano
window.senadoresEmbed.nano = function(template, data) {
  return template.replace(/\{([\w\.]*)\}/g, function(str, key) {
    var keys = key.split("."), v = data[keys.shift()];
    for (var i = 0, l = keys.length; i < l; i++) v = v[keys[i]];
    return (typeof v !== "undefined" && v !== null) ? v : "";
  });
};

window.senadoresEmbed.getRandomClass = function(){
  var classes = ['boxItem--primary','boxItem--secondary','boxItem--tertiary','boxItem--quaternary'];
  return classes[Math.floor(Math.random()*classes.length)];
};

window.senadoresEmbed.unique = function(list) {
  var result = [];
  $.each(list, function(i, e) {
    if ($.inArray(e, result) == -1) result.push(e);
  });
  return result;
};

/* RANKINGS SENADORES */
if ((document.getElementsByClassName('senador-embed-ranking').length>0) && !window.jQuery) {
    console.error('Jquery es requerido para utilizar el plugin de embed de Senadores');
} else {
    $(function(){
        if(!window.senadoresEmbed.initializedRanking) {
      window.senadoresEmbed.hasModal = (typeof $().modal == 'function');
      window.senadoresEmbed.initRanking();
        }
    });
}

window.senadoresRankingURL = window.municipiosRankingURL;
/* CONFIGURACION RANKING SENADORES */
window.senadoresEmbed.initRanking = function(){
    window.senadoresEmbed.initializedRanking = true;

    var embedsIds = [];
    var embeds = {};
    var wrapTpl = window.senadoresEmbed.html['wrap'];
    var wrapData = {
        msg: '<span class="senador-embed-loader">cargando...</span>'
    };

    //Embed de rankings
    $('.senador-embed-ranking').each(function(){
        var container = $(this);
        var id = container.data('id');
        var idModal = 'senador-embed-ranking-'+id+'-modal';
        var colorClass = container.data('class');
        var copy = container.data('copy');
        copy = (copy)?window.senadoresEmbed.html['copy']:'';
        colorClass = (colorClass)?colorClass:window.senadoresEmbed.getRandomClass();

        //loading
        wrapData.colorClass = (colorClass)?colorClass:window.senadoresEmbed.getRandomClass();
        container.html(window.senadoresEmbed.nano(wrapTpl, wrapData));

        if(window.senadoresEmbed.hasModal){
            var modalTpl = window.senadoresEmbed.html['modal'];
            var modalData = {
                id: idModal,
                // generador_link: window.senadoresRankingURL + '/api/embed-l',
                code: '<div id="prsenadores-ranking-'+id+'" data-pym-src="'+window.senadoresRankingURL+'/api/ranking-senador/'+id+'?class='+colorClass+'"></div><script src="https://cdnjs.cloudflare.com/ajax/libs/pym/0.4.5/pym.min.js" type="text/javascript"></script>',
            };
            $('body').append(window.senadoresEmbed.nano(modalTpl, modalData));
        }

        embedsIds.push(id);
        embeds[id] = {
            id: id,
            idModal: idModal,
            copy: copy,
            container: container,
            colorClass: colorClass
        };
    });

    if(embedsIds.length){
        $.getJSON(window.senadoresRankingURL+'/api/ranking-legislador/'+embedsIds.join(','))
            .then(function(datas){
                $.each(datas,function(ix,d){
                    if(d){
                        var data = {
                            title: d.title,
                            obj: d.obj,
                            indicador: d.indicador,
                            idModal: embeds[ix].idModal,
                            copy: embeds[ix].copy,
                            colorClass: embeds[ix].colorClass,
                            ver_todos_link: window.senadoresRankingURL + '/rankings-'+d.obj.entidad+((d.obj.entidad == 'senador')?'es':'s')+'/'+ ix,
                            base_path: window.senadoresRankingURL
                        };
                        if(data.obj.fuente){
                            data.obj.fuente = 'Fuente: '+ data.obj.fuente;
                        }
                        if(d.data.length>0){
                            try{
                                d.data = window.senadoresEmbed.numberFormatData(d);
                                $.each(d.data,function(i,e){
                                    if(e.foto){
                                        e.avatar = data.base_path+'/uploads/'+d.obj.entidad+((d.obj.entidad == 'senador')?'es':'s')+'/'+e.foto;
                                    } else {
                                        e.avatar = data.base_path+'/img/avatar.gif';
                                    }
                                    data['top'+(i+1)] = e;
                                });
                                var tpl = window.senadoresEmbed.html['senador'];
                                embeds[ix].container.html(window.senadoresEmbed.nano(tpl, data));
                            }catch(e){
                                wrapData.msg = '<p class="senadores-embed-error">Ocurrió un error</p>';
                                embeds[ix].container.html(window.senadoresEmbed.nano(wrapTpl, wrapData));
                            }
                        } else {
                        wrapData.msg = '<p class="senadores-embed-error">No hay información disponible</p>';
                        embeds[ix].container.html(window.senadoresEmbed.nano(wrapTpl, wrapData));
                        }
                    }else{
                        console.error('Error al cargar la info de ', ix);
                        embeds[ix].container.remove();
                    }

                    //iframe height
                    if(typeof pymChild === 'undefined'){
                    }else{
                        pymChild.sendHeight();
                    }
                });
            })
            .fail(function(d){
              return;
                console.error(d);
            });
    }
};

window.senadoresEmbed.numberFormatDataByIndicador = function(id,valor){
    var res = [2,'',''];
    switch(parseInt(id)){
      case 1:
        res = [0,'','prop.'];
      break;
      case 3:
      case 5:
        res = [2,'','%'];
      break;
      case 4:
        res = [0,'$',''];
      case 7:
        res = [0,'','emp.'];
      break;
    }

    return window.senadoresEmbed.numberFormat(valor,res[0],res[1],res[2])

};

window.senadoresEmbed.numberFormatData = function(ranking){
  var resp = [];

  var dict = {
    1:[0,'','propiedades'],
    2:[0,'$',''],
    4:[0,'$',''],
    7:[0,'','propiedades'],
    8:[0,'','períodos'],
    21:[0,'','períodos']
  };

  $(ranking.data).each(function(i,e){
    var formatted = false;

    if(ranking.indicador){
      e.valor =  window.senadoresEmbed.numberFormatDataByIndicador(ranking.indicador.id,e.valor);
      formatted = true;
    }

    if(!formatted && ranking.obj && ranking.obj.id){
        e.valor = window.senadoresEmbed.numberFormat(e.valor,wraps[0],wraps[1],wraps[2]);
    }
    resp.push(e)
  });

  return resp;

};


window.senadoresEmbed.numberFormat = function(number, decimals, pre, post){
  if(window.jQuery && window.jQuery.number ){
    var num = window.jQuery.number( number, decimals, '.', ',' );
        //num = (num==0)?'':num;
        pre = (num&&pre)?pre+' ':'',
        post = (num&&post)?' '+post:'';
    var resp = pre + num + post;
        resp = resp.replace('.00','');
    return (resp!='')?resp:'n/d';
  }else{
    return number;
  }
}

